import $ from "jquery";

import("swiper/scss");
import("swiper/scss/autoplay");
import("swiper/scss/effect-fade");

(async () => {
  const { default: Swiper } = await import("swiper");
  const { Autoplay, EffectFade } = await import("swiper/modules");
  $(".banner--type-4.has-slider .swiper").each(function () {
    new Swiper(this, {
      modules: [Autoplay, EffectFade],
      effect: "fade",
      centeredSlides: true,
      slidesPerView: 1,
      autoHeight: true,
      autoplay: true,
      speed: 1000,
      loop: true,
    });
  });
})();
